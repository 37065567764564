import styled from 'styled-components'

const TextField = styled.input`
  display: inline-block;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  background: #b3bfbb;
  font-size: 17px;
  font-weight: normal;
  color: white;
  border: none;
  border-radius: 30px;
  -webkit-appearance: none;
  padding: 0.9em 1.5em;

  ::-webkit-input-placeholder {
    color: white;
  }
  ::-moz-placeholder {
    color: white;
  }
  :-ms-input-placeholder {
    color: white;
  }
  :-moz-placeholder {
    color: white;
  }

  @media (max-width: 479px) {
    border-radius: 30px !important;
    width: 100%;
  }

  /* Maybe remove later */
  width: 300px;
  max-width: 100%;

  &.white {
    text-align: center;
    background: white;
    color: #0c2721;
    border: 2px solid #ddd;

    ::-webkit-input-placeholder {
      color: #999;
    }
    ::-moz-placeholder {
      color: #999;
    }
    :-ms-input-placeholder {
      color: #999;
    }
    :-moz-placeholder {
      color: #999;
    }

    &:focus {
      border-color: #0c2721;
    }
  }
`

export default TextField
