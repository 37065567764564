import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'

import trashIcon from '../assets/trash-icon.svg'
import { useCart } from '../useCart'
import Quantity from '../product/Quantity'

export const Container = styled.div`
  display: flex;
  margin: 20px 0;

  &.is-add {
    background: #f6f8f7;
  }
`

export const Image = styled.div`
  width: 80px;
  background: #eee;
  margin-right: 16px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

export const Name = styled(S.Text)`
  font-size: 17px;
  color: #002931;
  font-weight: 600;
`

export const Price = styled(S.Text)`
  font-size: 14px;
  color: #002931;
  font-weight: 600;
  margin-bottom: 15px;
`

export const Remove = styled.div`
  width: 30px;
  height: 30px;
  background: url(${trashIcon}) center / auto no-repeat;
  opacity: 0;
  cursor: pointer;

  @media (max-width: 767px) {
    opacity: 0.4;
  }

  ${Container}:hover & {
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
  }
`

export const Inner = styled.div`
  flex-grow: 1;

  &.is-add {
    padding: 15px 0;
  }
`

export const RegularPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`

const RegularPrice = styled(Name)`
  font-size: 14px;
  color: #abb9b5;
  font-weight: 600;
  margin-bottom: 15px;
  margin-right: 10px;
  text-decoration: line-through;
`

interface Props {
  id: string
  name: string
  price: string
  regularPrice?: string
  variantId: string
  quantity: number
  image: any
}

const CartItem: React.SFC<Props> = ({
  name,
  id,
  quantity,
  price,
  regularPrice,
  image,
}) => {
  const { removeFromCart, updateLineItemQuantity } = useCart()

  const setQuantity = (arg: number | Function) =>
    typeof arg === 'number'
      ? updateLineItemQuantity(id, arg)
      : updateLineItemQuantity(id, arg(quantity))

  return (
    <Container>
      <Image
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <Inner>
        <Name>{name}</Name>
        {regularPrice ? (
          <RegularPriceWrapper>
            <RegularPrice>${regularPrice}</RegularPrice>
            <Price>${price}</Price>
          </RegularPriceWrapper>
        ) : (
          <Price>${price}</Price>
        )}
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}>
          <Quantity quantity={quantity} setQuantity={setQuantity} />
          <Remove onClick={() => removeFromCart(id)} />
        </div>
      </Inner>
    </Container>
  )
}

export default CartItem
