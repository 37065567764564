import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 0 30px;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 479px) {
    padding: 0 20px;
  }

  &.small {
    max-width: 1020px;
  }

  &.extraSmall {
    max-width: 639px;
  }
`

export default Wrapper
