import * as React from 'react'
import Helmet from 'react-helmet'

import * as S from '../styles'
import Footer from './Footer'
import Header from './Header/Header'

interface Props {
  headerTheme?: string
}

const App: React.SFC<Props> = ({ children, headerTheme }) => (
  <>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Work+Sans:400,500,700"
        rel="stylesheet"
      />
    </Helmet>
    <S.GlobalStyle />
    <Header theme={headerTheme} />
    {children}
    <Footer />
  </>
)

export default App
