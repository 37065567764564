import { Link } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'
import facebookIcon from '../assets/facebook-icon.svg'
import instagramIcon from '../assets/instagram-icon.svg'
import logo from '../assets/logo-white.svg'
import * as S from '../styles'

const Container = styled(S.Section)`
  padding: 60px;
  background: #0c2721;
`

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > * {
      margin: 25px 0;
    }
  }
`

const Logo = styled.img`
  display: block;
  height: 50px;
`

const Nav = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > * {
      margin: 25px 0;
    }
  }
`

const NavLink = styled(Link)`
  display: inline-block;
  font-size: 15px;
  font-weight: 900;
  margin-right: 40px;
  color: white;
  text-decoration: none;

  @media (max-width: 1023px) {
    display: block;
    margin-right: 0;
  }
`

const Social = styled.div`
  display: flex;
`

const SocialItem = styled.a`
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 15px;

  &:last-of-type {
    margin-right: 0;
  }
`

const Copyright = styled(S.Text)`
  width: 100%;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 0;

  @media (max-width: 1023px) {
    margin-top: 25px;
  }
`

const Footer: React.SFC = () => {
  return (
    <Container>
      <S.Wrapper className="small">
        <Inner>
          <NavLink to="/">
            <Logo src={logo} />
          </NavLink>
          <Nav>
            <NavLink to="/shop">Shop Our Wines</NavLink>
            <NavLink to="/story">Our Story</NavLink>
            <NavLink to="/press">Press</NavLink>
            <NavLink to="/contact">Contact Us</NavLink>
          </Nav>
          <Social>
            <SocialItem
              href="https://www.instagram.com/lieuditwinery"
              target="_blank">
              <img src={instagramIcon} />
            </SocialItem>
            <SocialItem
              href="https://www.facebook.com/lieuditwinery"
              target="_blank">
              <img src={facebookIcon} />
            </SocialItem>
          </Social>
          <Copyright>
            &copy; {new Date().getFullYear()} Lieu Dit Winery.
          </Copyright>
        </Inner>
      </S.Wrapper>
    </Container>
  )
}

export default Footer
