import styled from 'styled-components'

export default styled.h1`
  font-weight: 900;

  &.large {
    font-size: 65px;
    line-height: 73px;
    color: white;

    @media (max-width: 479px) {
      font-size: 45px;
      line-height: 52px;
    }
  }

  &.small {
    font-size: 16px;
    line-height: 24px;
    color: #d8e1dc;
    text-transform: uppercase;
    letter-spacing: 1.06px;
  }

  &.medium {
    font-size: 36px;
    line-height: 49px;
    color: #0c2721;

    @media (max-width: 479px) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  &.smedium {
    font-size: 24px;
    line-height: 30px;
    color: #0c2721;

    @media (max-width: 479px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &.center {
    text-align: center;
  }

  &.white {
    color: white;
  }
`
