import styled from 'styled-components'

const Text = styled.div`
  font-size: 19px;
  line-height: 27px;
  color: black;

  &.large {
    font-size: 20px;
  }

  &.medium {
    font-size: 16px;
  }

  &.small {
    font-size: 15px;
    line-height: 24px;
  }

  &.xsmall {
    font-size: 12px;
    line-height: 24px;
  }

  &.white {
    color: white;
  }

  &.center {
    text-align: center;
  }

  &.green {
    color: #435f5a;
  }

  &.semiBold {
    font-weight: 500;
  }

  &.uppercase {
    text-transform: uppercase;
  }
`

export default Text
