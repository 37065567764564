import * as React from 'react'
import * as store from 'store'
import { CartContext, client } from './CartProvider'
import track from './track'

const getPromoError = cart =>
  cart
    ? cart.userErrors && cart.userErrors[0] && cart.userErrors[0].message
    : false

export const useCart = () => {
  const context = React.useContext(CartContext) as any

  if (!context) {
    throw new Error('useCart must be used within a CartProvider')
  }

  const safeCartAction = async (method, cartId, ...args) => {
    try {
      const newCart = await client.checkout[method](cartId, ...args)

      context.setCart(newCart)

      return newCart
    } catch (e) {
      // @ts-ignore
      Sentry.captureException(e)

      const cart = await client.checkout.create()
      store.set('checkoutId', cart.id)
      context.setCart(cart)
      return cart
    }
  }

  const getCart = async () => {
    if (context.cart) return context.cart

    // TODO: Change this to some sort of friendly error message to reload or try again
    do {
      await new Promise(resolve => setTimeout(resolve, 1000))
    } while (!context.cart)

    return context.cart
  }

  const addToCart = async (variantId: string, quantity = 1) => {
    const cart = await getCart()

    const newCart = await safeCartAction('addLineItems', cart.id, [
      {
        quantity,
        variantId,
      },
    ])

    if (cart.userErrors && cart.userErrors[0] && cart.userErrors[0].message) {
      console.log('reset the cart')
      Sentry.captureException(cart.userErrors[0].message)
    }

    track('Product Added', {
      variantId: variantId,
      quantity: quantity,
    })
    context.setCart(newCart)
  }

  const getCount = () => {
    if (context.cart) {
      const { lineItems } = context.cart

      return lineItems.reduce((sum, li) => {
        if (/time-capsule/.test(li.variant.sku)) {
          return sum + li.quantity * 3
        } else {
          return sum + li.quantity
        }
      }, 0)
    }

    return 0
  }

  const removeFromCart = async (id: string) => {
    const cart = await getCart()

    await safeCartAction('removeLineItems', cart.id, [id])

    track('Product Removed', {
      id,
    })
  }

  const updateLineItemQuantity = async (
    lineItemId: string,
    quantity: number
  ) => {
    const cart = await getCart()

    await safeCartAction('updateLineItems', cart.id, [
      { id: lineItemId, quantity: quantity },
    ])
  }

  const goToCheckout = async () => {
    const cart = await getCart()

    track('Checkout Started')

    window.location.href = cart.webUrl
  }

  const getCountBasedDiscount = () => {
    const count = getCount()

    if (count > 5) return 20

    return 0
  }

  const getCountBasedDiscountCode = () => {
    const count = getCount()

    if (count > 5) return '20off'

    return null
  }

  const applyDiscount = async (code: string) => {
    const cart = await getCart()
    const newCart = await safeCartAction('addDiscount', cart.id, code)

    if (getPromoError(newCart)) {
      track('Coupon Denied', {
        code,
      })
    } else {
      track('Coupon Applied', {
        code,
      })
    }
  }

  const promoError: string | false = getPromoError(context.cart)

  const getDiscount = () => {
    const cart = context.cart

    if (!cart) return 0

    const subtotalBeforeDiscounts = parseFloat(
      cart.lineItemsSubtotalPrice.amount
    )
    const subtotal = parseFloat(cart.subtotalPriceV2.amount)

    // Floating point numbers are fun
    return Math.round((subtotalBeforeDiscounts - subtotal) * 100) / 100
  }

  const getPromo = () => {
    if (!context.cart) {
      return undefined
    }

    const discountApplications = context.cart.discountApplications

    if (discountApplications && discountApplications.length > 0) {
      return discountApplications[0].code
    }

    return undefined
  }

  const removePromo = async () => {
    const cart = await getCart()

    await safeCartAction('removeDiscount', cart.id)

    track('Coupon Removed')
  }

  return {
    addToCart,
    applyDiscount,
    cart: context.cart,
    getCart,
    getCount,
    getCountBasedDiscount,
    getCountBasedDiscountCode,
    getDiscount,
    getPromo,
    goToCheckout,
    isOpen: context.isOpen,
    promoError,
    removeFromCart,
    removePromo,
    toggleOpen: context.toggleOpen,
    updateLineItemQuantity,
  }
}
