import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Work Sans', sans-serif;
  }

  body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  img {
    max-width: 100%;
  }

  button {
    -webkit-appearance: none;
    outline: none;
    border: none;
    cursor: pointer;
  }
`

export default GlobalStyle
