import * as React from 'react'
import styled from 'styled-components'
import downArrow from '../assets/down-arrow.svg'
import upArrow from '../assets/up-arrow.svg'
import * as S from '../styles'
import track from '../track'
import { useCart } from '../useCart'

interface PromoContainerProps {
  open?: boolean
}

const PromoContainer = styled.div<PromoContainerProps>`
  padding: 16px 0;
  border-top: 1px solid #e4eaee;
  background: ${props =>
      props.open
        ? `url(${upArrow}) right 20px / auto no-repeat;`
        : `url(${downArrow}) right 20px / auto no-repeat;`}
    ${S.Text} {
    cursor: pointer;
    user-select: none;
  }

  .input-container {
    height: ${props => (props.open ? '50px' : '0')};
    overflow: hidden;
    transition: height 0.1s ease-in 0s;
  }

  input[type='text'] {
    background: white;
    color: #0c2721;
    border: 1px solid #ddd;
    font-size: 15px;
    height: 40px;
    border-radius: 4px;
    width: 100%;
    margin-top: 10px;
    padding: 0 15px;
    outline: none;
  }
`

const Promo: React.SFC<{}> = () => {
  const [open, setOpen] = React.useState(false)
  const [code, setCode] = React.useState('')

  const {
    applyDiscount,
    promoError,
    getPromo,
    removePromo,
    getCountBasedDiscountCode,
  } = useCart()

  const countBasedDiscountCode = getCountBasedDiscountCode()

  React.useEffect(() => {
    const applyAsyncPromo = async code => {
      if (code && code.length > 0) {
        await applyDiscount(code)
        track('Coupon Entered', {
          code,
        })
      }
    }

    setOpen(countBasedDiscountCode ? true : false)
    setCode(countBasedDiscountCode || '')
    applyAsyncPromo(countBasedDiscountCode)
  }, [countBasedDiscountCode])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCode(e.currentTarget.value)

  const applyPromo = () => {
    if (code && code.length > 0) {
      applyDiscount(code)
      track('Coupon Entered', {
        code,
      })
    }
  }

  const promo = getPromo()

  return (
    <PromoContainer open={open}>
      <S.Text style={{ fontSize: 14 }} onClick={() => setOpen(!open)}>
        Have a Promo Code?
      </S.Text>
      {promoError ? promoError : null}
      <div className="input-container">
        <input
          type="text"
          value={code}
          onChange={onChange}
          placeholder="Enter promo code"
          onBlur={applyPromo}
        />
      </div>
      {promo ? (
        <div>
          {promo} <button onClick={removePromo}>Remove</button>
        </div>
      ) : null}
    </PromoContainer>
  )
}

export default Promo
