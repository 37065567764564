import styled from 'styled-components'

interface IProps {
  width?: string
  breakpoints?: {}
}

const generateBreakpoints = (breakpoints: {}) => {
  let styles = ``

  Object.keys(breakpoints)
    .reverse()
    .forEach(x => {
      if (breakpoints.hasOwnProperty(x)) {
        styles += `
      @media (max-width: ${x}px) {
        width: ${breakpoints[x]};
        ${breakpoints[x] === '100%' ? `margin-bottom: 45px` : ''}
      }
    `
      }
    })

  return styles
}

export const Grid = styled.div<{
  align?: string
}>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: ${p => p.align || 'flex-start'};
`

export const GridItem = styled.div<IProps>`
  width: ${p => (p.width ? p.width : '100%')};
  &:last-of-type {
    margin-bottom: 0;
  }

  ${(p: IProps) => p.breakpoints && generateBreakpoints(p.breakpoints)}
`
