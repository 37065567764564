import styled from 'styled-components'
import homeIntroMap from '../assets/home-intro-map.png'
import intro from '../assets/home-intro.jpg'
import subscribe from '../assets/subscribe-bg.jpg'
import whoWeAre from '../assets/who-we-are-bg.jpg'

const Section = styled.div`
  display: block;

  &.center {
    text-align: center;
  }

  &.intro {
    padding: 210px 0 116px;
  }

  &.main {
    padding: 116px 0;
    background: linear-gradient(
      180deg,
      rgba(216, 225, 220, 0.182009) 0%,
      rgba(0, 40, 33, 0.646635) 100%
    );
  }

  &.sub {
    padding: 80px 0;
    background: white;
  }

  &.sub-parts {
    padding: 30px 0;
    background: white;
  }

  &.home-intro {
    background: url(${intro}) top center / cover no-repeat;
    position: relative;

    &:before,
    &:after {
      display: block;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:after {
      background: linear-gradient(
        180deg,
        rgba(216, 225, 220, 0.182009) 0%,
        rgba(0, 40, 33, 0.646635) 100%
      );
    }

    &:before {
      background: url(${homeIntroMap}) right center / contain no-repeat;
    }

    @media (max-width: 767px) {
      background-position: 40% center;

      &:before {
        display: none;
      }
    }
  }

  &.product-intro {
    background-color: #f2f5f3;

    @media (max-width: 767px) {
      padding: 90px 0 45px;
    }
  }

  &.story-intro {
    position: relative;

    &:after {
      display: block;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:after {
      background: linear-gradient(
        180deg,
        rgba(216, 225, 220, 0.182009) 0%,
        rgba(0, 40, 33, 0.46635) 100%
      );
    }

    @media (max-width: 767px) {
      padding: 90px 0 45px;
    }
  }

  &.who-we-are {
    background: url(${whoWeAre}) top center / cover no-repeat;
    position: relative;

    &:after {
      display: block;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(216, 225, 220, 0.182009) 0%,
        rgba(0, 40, 33, 0.646635) 100%
      );
    }
  }

  &.subscribe {
    background: url(${subscribe}) top center / cover no-repeat;
    position: relative;

    &:after {
      display: block;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(216, 225, 220, 0.182009) 0%,
        rgba(0, 40, 33, 0.646635) 100%
      );
    }
  }
`

export default Section
