import { Link } from 'gatsby'
import styled from 'styled-components'

import cartWhite from '../../assets/cart-white.svg'
import cart from '../../assets/cart.svg'
import closeWhite from '../../assets/close-circle-white.svg'
import close from '../../assets/close-circle.svg'
import menuWhite from '../../assets/menu-white.svg'
import menu from '../../assets/menu.svg'

export const Toggle = styled.div`
  display: block;
  width: 30px;
  height: 30px;
  background: url(${menuWhite}) center / cover no-repeat;
`

export const NavLink = styled(Link)`
  display: inline-block;
  font-size: 15px;
  font-weight: 900;
  margin-right: 40px;
  color: white;
  text-decoration: none;

  &.is-active {
    color: #002821 !important;
  }
`

export const CartButton = styled.button`
  display: block;
  width: 30px;
  height: 30px;
  background: url(${cartWhite}) center / cover no-repeat;
`

export const Container = styled.div<{
  isSticky?: boolean
  theme?: string
}>`
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  padding: 30px 0;
  background: transparent;
  transition: all 0.1s ease-in 0.1s;

  @media (max-width: 859px) {
    position: ${p => (p.isSticky ? 'fixed' : 'absolute')};
    padding: ${p => (p.isSticky ? '15px 0' : '30px 0')};
    background: ${p => (p.isSticky ? '#c5d5d7' : 'transparent')};
  }

  ${p =>
    p.theme === 'dark' &&
    !p.isSticky &&
    `
  ${Toggle} {
    background-image: url(${menu});
  }

  ${NavLink} {
  color: #AAB8B5;
  }

  ${CartButton} {
    background-image: url(${cart});
  }
`}
`

export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const Desktop = styled.div`
  display: block;
  @media (max-width: 859px) {
    display: none;
  }
`

export const Mobile = styled.div`
  display: none;
  @media (max-width: 859px) {
    display: block;
  }
`

export const Logo = styled.img`
  display: block;
  height: 50px;
  @media (max-width: 859px) {
    height: 30px;
  }
`

export const Nav = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const MobileNav = styled.div<{
  isOpen?: boolean
}>`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00241d;
  padding: 30px;
  transition: transform 0.1s ease-in 0.1s;
  transform: ${p => (p.isOpen ? 'translateX(0%)' : 'translateX(-100%)')};

  ${Inner} {
    align-items: flex-start;
  }

  ${Nav} {
    margin-top: 45px;
  }

  ${NavLink} {
    display: block;
    width: 100%;
    margin-right: 0;
    font-size: 40px;
    margin-bottom: 45px;
  }
`

export const MobileNavClose = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url(${closeWhite}) center / cover no-repeat;
`

export const CartContainer = styled.div<{
  isOpen?: boolean
}>`
  display: block;
  position: fixed;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 400px;
  height: 100%;
  background: white;
  padding: 30px 30px 0;
  transition: transform 0.1s ease-in 0.1s;
  box-shadow: 0 16px 11px 2px rgba(0, 0, 0, 0.1);
  transform: ${p => (p.isOpen ? 'translateX(0%)' : 'translateX(100%)')};
`

export const CartClose = styled.div`
  display: block;
  width: 30px;
  height: 30px;
  background: url(${close}) center / cover no-repeat;
  float: left;
  cursor: pointer;
`

export const CartCount = styled.div<{
  count?: number
}>`
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  background: url(${cart}) center / cover no-repeat;
  float: right;

  &:after {
    display: ${p => (p.count && p.count > 0 ? 'block' : 'none')};
    content: '${p => p.count}';
    position: absolute;
    top: -16px;
    right: -16px;
    background: #002821;
    color: white;
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 26px;
    font-weight: 600;
    border-radius: 50%;
  }
`
