import * as React from 'react'
import logo from '../../assets/logo-white.svg'
import logoDark from '../../assets/logo.svg'
import * as S from '../../styles'
import track from '../../track'
import { useCart } from '../../useCart'
import Cart from '../Cart'
import * as HS from './styles'

interface Props {
  theme?: string
}

const Header: React.SFC<Props> = ({ theme }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [isSticky, setSticky] = React.useState(false)
  const { isOpen: cartOpen, toggleOpen: toggleCartOpen, getCount } = useCart()

  const handleScroll = () => {
    setSticky(window.scrollY > 60)
  }

  const toggleCart = () => {
    if (!cartOpen) {
      track('Cart Viewed')
    }
    toggleCartOpen()
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <HS.Container isSticky={isSticky} theme={theme}>
      <S.Wrapper className={theme === 'dark' ? '' : 'small'}>
        <HS.Desktop>
          <HS.Inner>
            <HS.NavLink to="/">
              <HS.Logo src={theme === 'dark' ? logoDark : logo} />
            </HS.NavLink>
            <HS.Nav>
              <HS.NavLink to="/shop">Shop Our Wines</HS.NavLink>
              <HS.NavLink to="/story">Our Story</HS.NavLink>
              <HS.NavLink to="/press">Press</HS.NavLink>
              <HS.NavLink to="/contact">Contact Us</HS.NavLink>
              <HS.CartButton onClick={toggleCart} />
            </HS.Nav>
          </HS.Inner>
        </HS.Desktop>
        <HS.Mobile>
          <HS.Inner>
            <HS.Toggle onClick={() => setMobileOpen(true)} />
            <HS.NavLink to="/" style={{ marginRight: 0 }}>
              <HS.Logo src={theme === 'dark' && !isSticky ? logoDark : logo} />
            </HS.NavLink>
            <HS.CartButton onClick={toggleCart} />
          </HS.Inner>
          <HS.MobileNav isOpen={mobileOpen}>
            <HS.Inner>
              <HS.NavLink
                to="/"
                style={{ display: 'inline-block', width: 'auto' }}>
                <HS.Logo src={logo} />
              </HS.NavLink>
              <HS.MobileNavClose onClick={() => setMobileOpen(false)} />
              <HS.Nav style={{ width: '100%' }}>
                <HS.NavLink to="/shop">Shop Wines</HS.NavLink>
                <HS.NavLink to="/capsules">Time Machine</HS.NavLink>
                <HS.NavLink to="/story">Our Story</HS.NavLink>
                <HS.NavLink to="/press">Press</HS.NavLink>
                <HS.NavLink to="/contact">Contact Us</HS.NavLink>
              </HS.Nav>
            </HS.Inner>
          </HS.MobileNav>
        </HS.Mobile>
        <HS.CartContainer isOpen={cartOpen}>
          <HS.CartClose onClick={toggleCart} />
          <HS.CartCount count={getCount()} />
          <Cart />
        </HS.CartContainer>
      </S.Wrapper>
    </HS.Container>
  )
}

export default Header
