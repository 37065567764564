import styled from 'styled-components'
import loadingGreen from '../assets/loading-green.svg'
import loadingWhite from '../assets/loading-white.svg'

const Button = styled.button<{
  as?: string
}>`
  display: inline-block;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 17px;
  font-weight: 900;
  background: white;
  color: #0c2721;
  border: 1px solid #ddd;
  border-radius: 30px;
  -webkit-appearance: none;
  padding: 0.9em 1.5em;
  text-decoration: none;
  text-align: center;

  @media (max-width: 479px) {
    width: 100%;
    border-radius: 30px !important;
  }

  &.loadingGreen,
  &.loadingWhite {
    background-repeat: no-repeat;
    background-size: 24px auto;
    background-position: 15px center;
    padding-left: 50px;
  }

  &.loadingGreen {
    background-image: url(${loadingGreen});
  }

  &.loadingWhite {
    background-image: url(${loadingWhite});
  }

  &.light,
  &.green,
  &.darkGreen {
    color: white;
  }

  &.light {
    background-color: rgba(255, 255, 255, 0.5);
    border-color: transparent;
  }

  &.green {
    background-color: #435f5a;
    border: none;
  }

  &.darkGreen {
    background-color: #0c2721;
    border: none;
  }
`

export default Button
