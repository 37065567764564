import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import * as S from '../styles'
import { useCart } from '../useCart'
import { usePromiseWithLoading } from '../usePromiseWithLoading'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-bottom: 45px;

  @media (max-width: 767px) {
    margin-bottom: 30px;
    height: auto;
  }
`
const SoldOut = styled.div`
  margin-top: 20px;
  text-weight: bold;
`

const Image = styled.img`
  max-width: 90%;
  margin-bottom: 1em;
  height: 245px;

  @media (max-width: 767px) {
    max-width: 80%;
    margin-bottom: 0.5em;
  }
`

const Name = styled(S.Text)`
  font-size: 18px;
  margin-bottom: 0;
  color: #002821;
`

const Price = styled(Name)`
  font-weight: 500;
`

const AddToCart = styled(S.Button)`
  margin-top: 1em;
`

export const RegularPriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`

const RegularPrice = styled(Name)`
  color: #abb9b5;
  font-weight: 500;
  text-decoration: line-through;
  margin-right: 10px;
`

interface IProps {
  variantId: string
  soldOut: boolean
  name: string
  slug: string
  image?: string
  price: string
  regularPrice?: string
}

const ProductItem: React.SFC<IProps> = ({
  variantId,
  soldOut,
  name,
  image,
  slug,
  price,
  regularPrice,
}) => {
  const { addToCart, toggleOpen, isOpen } = useCart()

  const add = async () => {
    await addToCart(variantId)

    if (!isOpen) {
      toggleOpen()
    }
  }

  const [addAction, loading] = usePromiseWithLoading(add)

  return (
    <Container>
      <Link to={`/shop/${slug}`} style={{ textDecoration: 'none' }}>
        <Image src={image} alt={name} />
        <div style={{ marginTop: 'auto' }}>
          <Name>{name}</Name>
          {regularPrice ? (
            <RegularPriceWrapper>
              <RegularPrice>${regularPrice}</RegularPrice>
              <Price>${price}</Price>
            </RegularPriceWrapper>
          ) : (
            <Price>${price}</Price>
          )}
        </div>
      </Link>
      {soldOut ? (
        <SoldOut>sold out</SoldOut>
      ) : loading ? (
        <AddToCart className="loadingGreen">Add to Cart</AddToCart>
      ) : (
        <AddToCart onClick={addAction}>Add to Cart</AddToCart>
      )}
    </Container>
  )
}

export default ProductItem
