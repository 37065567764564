import { StaticQuery, graphql } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'
import { useCart } from '../useCart'

import bottleSelected from '../assets/bottle-selected.svg'
import bottle from '../assets/bottle.svg'

import { encodeShopifyVariantID } from '../utils/shopify-ids'
import CartItem from './CartItem'
import Promo from './Promo'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;
`

const Title = styled(S.Heading)`
  font-weight: normal;
  color: #002931;
  font-size: 24px;
  margin-bottom: 16px;
`

const DiscountText = styled(S.Text)`
  font-size: 14px;
  color: #002931;
`

const Price = styled(S.Text)`
  color: #788995;
  font-size: 14px;
  margin: 5px 0;
`

const Bottle = styled.div<{
  active?: boolean
}>`
  width: 16px;
  height: 40px;
  margin-right: 10px;
  background-image: url(${p => (p.active ? bottleSelected : bottle)});
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
`

const Total = styled(S.Text)`
  font-size: 16px;
  margin: 10px 0 30px;
`

const CheckoutButton = styled.div`
  display: block;
  position: sticky;
  background: white;
  bottom: 0;
  width: 100%;
  padding: 30px 0;

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: -30px;
    width: calc(100% + 60px);
    height: 2px;
    background: rgb(228, 234, 238);
  }

  ${S.Button} {
    width: 100%;
  }
`

interface CartItem {
  id: string
  image: string
  name: string
  price: string
  regularPrice?: string
  quantity: number
  variantId: string
}

const Cart: React.SFC<{ products: any }> = ({ products }) => {
  const {
    cart,
    getCount,
    goToCheckout,
    getCountBasedDiscount,
    getDiscount,
  } = useCart()
  const count = getCount()
  const countBasedDiscount = getCountBasedDiscount()
  const lineItems = cart ? cart.lineItems : []
  const cartItems: CartItem[] = lineItems
    .map(li => {
      if (!li.variant) return undefined

      const productNode = products.find(
        p => p.node.variantId === encodeShopifyVariantID(li.variant.id)
      )

      if (!productNode) return undefined

      const product = productNode.node

      return {
        id: li.id,
        quantity: li.quantity,
        variantId: product.variantId,
        name: product.productInformation.name,
        price: li.quantity * li.variant.price.amount,
        regularPrice: li.quantity * li.variant.compareAtPrice,
        image: product.productInformation.mainImage.fluid.src,
      }
    })
    .filter(Boolean) as any // have to cast to any since TS doesn't narrow types on .filter(Boolean)

  const subtotalBeforeDiscount = cart ? cart.lineItemsSubtotalPrice.amount : 0
  const subtotal = cart ? cart.subtotalPriceV2.amount : 0
  const discount = getDiscount()

  return (
    <Container>
      <Title>Shopping Cart</Title>
      <DiscountText>Buy any 6 bottles and get 20% off.</DiscountText>
      <div
        style={{
          borderBottom: '1px solid #E4EAEE',
          display: 'flex',
          padding: '16px 0',
        }}>
        {[0, 1, 2, 3, 4, 5].map(index => (
          <Bottle key={index} active={index < count} />
        ))}
        <DiscountText
          style={{
            lineHeight: '1.5em',
            marginLeft: 20,
            opacity: 0.6,
            width: 130,
          }}>
          You currently get: <strong>{countBasedDiscount}% off</strong>
        </DiscountText>
      </div>
      <div>
        {cartItems.map(cartItem => (
          <CartItem key={cartItem.id} {...cartItem} />
        ))}
      </div>
      <Promo />
      <div
        style={{
          borderBottom: '2px solid #E4EAEE',
          borderTop: '1px solid #E4EAEE',
          padding: '5px 0 5px 0',
        }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Price>Subtotal</Price>
          <Price>
            <strong>${subtotalBeforeDiscount}</strong>
          </Price>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Price>Discount</Price>
          <Price>
            <strong>{discount ? `- $${discount}` : 'None'}</strong>
          </Price>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Total>Total</Total>
        <Total>
          <strong>${subtotal}</strong>
        </Total>
      </div>
      <CheckoutButton>
        <S.Button className="darkGreen" onClick={goToCheckout}>
          Checkout
        </S.Button>
      </CheckoutButton>
    </Container>
  )
}

const CartProducts = graphql`
  query {
    allContentfulShopifyProduct {
      edges {
        node {
          variantId
          productInformation {
            ... on ContentfulProduct {
              name
              mainImage {
                fluid(maxHeight: 450, quality: 80) {
                  src
                }
              }
            }
            ... on ContentfulBundle {
              name
              mainImage {
                fluid(maxHeight: 450, quality: 80) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`

const CartWithProducts = () => (
  <StaticQuery
    query={CartProducts}
    render={data => <Cart products={data.allContentfulShopifyProduct.edges} />}
  />
)

export default CartWithProducts
