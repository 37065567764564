import * as React from 'react'

import * as S from '../styles'
import Subscribe from './Subscribe'

const JoinCommunity: React.SFC<{}> = () => {
  return (
    <S.Section className="main subscribe center">
      <S.Wrapper style={{ position: 'relative', zIndex: 1 }}>
        <S.Heading as="h3" className="small">
          Cheers
        </S.Heading>
        <S.Heading as="h2" className="large" style={{ marginBottom: 15 }}>
          Join the community
        </S.Heading>
        <S.Text
          style={{
            color: 'white',
            marginBottom: 25,
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: 410,
          }}>
          Sign up for our newsletter to get updates on new wines and
          opportunities.
        </S.Text>
        <Subscribe />
      </S.Wrapper>
    </S.Section>
  )
}

export default JoinCommunity
