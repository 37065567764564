import * as React from 'react'

export const usePromiseWithLoading = fn => {
  const [loading, setLoading] = React.useState(false)

  const wrappedFn = async (...args) => {
    setLoading(true)
    await fn(...args)
    setLoading(false)
  }

  return [wrappedFn, loading]
}
