import * as React from 'react'
import styled from 'styled-components'

import plusIcon from '../assets/plus-icon.svg'
import minusIcon from '../assets/minus-icon.svg'

const QuantityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 17px;
  font-weight: 900;
  background: white;
  color: #0c2721;
  border: 1px solid #ddd;
  border-radius: 30px;
  padding: 0.9em 1em;
  text-align: center;
  position: relative;
  min-width: 115px;
  width: 130px;
  height: 52px;
  margin-right: 15px;

  .in-cart & {
    height: 30px;
    padding: 4px;
    width: 96px;
    min-width: 0;

    input {
      width: 36px;
      font-size: 14px;
    }
  }

  input {
    -webkit-appearance: none;
    background: none;
    width: 50px;
    text-align: center;
    font-size: 16px;
    color: #0c2721;
    font-weight: 600;
    border: none;
    outline: none;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  button {
    width: 30px;
    height: 20px;
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
  }

  button.minus {
    background-image: url(${minusIcon});
    &.faded {
      opacity: 0.4;
    }
  }

  button.plus {
    background-image: url(${plusIcon});
  }
`

interface Props {
  quantity: number
  setQuantity: Function
}

export const Quantity: React.SFC<Props> = props => {
  const increase = () => props.setQuantity(q => q + 1)
  const decrease = () => props.setQuantity(q => q - 1)

  return (
    <QuantityContainer>
      <button
        className={props.quantity === 1 ? 'minus faded' : 'minus'}
        onClick={props.quantity === 1 ? () => null : decrease}
      />
      <input
        type="number"
        value={props.quantity}
        onChange={e =>
          props.setQuantity(Math.abs(parseInt(e.currentTarget.value)))
        }
      />
      <button className="plus" onClick={increase} />
    </QuantityContainer>
  )
}

export default Quantity
