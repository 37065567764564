import addToMailchimp from 'gatsby-plugin-mailchimp'
import * as React from 'react'
import styled from 'styled-components'
import identify from '../identify'
import * as S from '../styles'
import track from '../track'

const Button = styled(S.Button).attrs({
  className: 'green',
})`
  @media (max-width: 499px) {
    margin-top: 15px;
    width: 100%;
  }
`

const TextField = styled(S.TextField)`
  border-radius: 30px 0 0 30px;

  @media (max-width: 499px) {
    background: transparent;
    border-bottom: 2px solid white;
    border-radius: 0 !important;
  }
`

const ErrorMessage = styled(S.Wrapper)`
  color: white;
  text-align: center;
  margin-top: 20px;

  a {
    color: white;
  }
`

const Subscribe: React.SFC<{}> = () => {
  const [success, setSuccess] = React.useState(undefined)
  const [error, setError] = React.useState(undefined)
  const [email, setEmail] = React.useState('')

  const handleSubmit = (e: any) => {
    e.preventDefault()

    addToMailchimp(email)
      .then(({ msg, result }: any) => {
        if (result !== 'success') {
          throw msg
        }

        track('Newsletter Subscribed', { email })
        identify({ email: email })

        setSuccess(msg)
        setError(undefined)
        setEmail('')
      })
      .catch((err: any) => {
        setError(err)
        setSuccess(undefined)
        setEmail('')
      })
  }

  return (
    <>
      {success ? (
        <S.Text className="large white">Thanks for subscribing!</S.Text>
      ) : (
        <form onSubmit={handleSubmit}>
          <TextField
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={e => setEmail(e.currentTarget.value)}
          />
          <Button
            type="submit"
            style={{
              borderRadius: '0 30px 30px 0',
            }}>
            Sign Up
          </Button>
        </form>
      )}
      {error && (
        <ErrorMessage dangerouslySetInnerHTML={{ __html: `${error}` }} />
      )}
    </>
  )
}

export default Subscribe
